import React from 'react'
import "./home.css"
import BigButton from '../utils/BigButton/BigButton'
import { Link } from 'react-router-dom'
import { useLangContext } from '../../Context/LangContext'
import homeTexts from '.././utils/Texts/homeTexts.json'
import HomeVideo from './video/AFZ02.mp4'
import MobileVideo from './video/AFZ02VERTICAL.mp4'
import { useEffect } from 'react'
import { MobileOnlyView, isMobileOnly } from 'react-device-detect';

const Home = () => {
const { selectedLanguage } = useLangContext()
const text = homeTexts[selectedLanguage];

useEffect(()=>{
  window.scrollTo(0,0);
},[])

  return (
   <>
   
    <div className={isMobileOnly ? "display-none":'homeBackgound'}>
      <video autoPlay playsInline muted className='home-video'>
        <source src={HomeVideo} type='video/mp4' />
      </video>
      <div className='gradient'>
      <div className='hero'>
        <div className='homeTitle'>
        <h1>{text.title1}<br></br>{text.title2}</h1>
        </div>
        <div className='button-box'>
        <Link to="https://test.widgets.winse.online/RentalGuarantee/pk-5e03433c-7b11-4b55-bef9-e2f347480cc7/" target="_blank" rel="noreferrer">
        <BigButton className="dark-button-home" name={text.productButton} />
        </Link>
        <Link to="/contacto">
        <BigButton className="dark-button-home" name={text.contactButton} />
        </Link>
        </div>
        
      </div>
      </div>
    </div>
   
    <MobileOnlyView>
    <div className='homeBackgound'>
      <video autoPlay playsInline muted className='home-video'>
        <source src={MobileVideo} type='video/mp4' />
      </video>
      <div className='gradient'>
      <div className='hero-mobile'>
        <div className='homeTitle-mobile'>
        <h1>{text.title1}<br></br>{text.title2}</h1>
        </div>
        <div className='mobile-button-box'>
        <Link to="/cotizador-alquiler">
        <BigButton className="dark-button-mobile-home" name={text.productButton} />
        </Link>
        <Link to="/contacto">
        <BigButton className="dark-button-mobile-home" name={text.contactButton} />
        </Link>
        </div>
      </div>
      </div>
    </div>

    </MobileOnlyView>
    </>
   
  )
}

export default Home
